<template>
    <label class="border-2 p-4 px-0 relative"
        :class="[{ 'opacity-50': disabled }, isOpen ? 'border-my-green' : 'border-gray-line', disabled ? '' : 'hover:border-my-green']">
        <div class="pl-4">
            <div class="flex items-center gap-3 text-gray-text relative ml-[32px]">
                <Icon :name="icon" class="absolute -left-8" size="20" />
                <span class=" text-[16px]/[19px]">{{ label }}</span>
            </div>
        </div>

        <UInputMenu :popper="{ offsetDistance: 25 }" v-model="model" :options="options2" placeholder=""
            option-attribute="mylabel" :value-attribute="'codeEn'"
            :search-attributes="['nameRu', 'nameEn', 'nameUz', 'codeRu', 'codeEn', 'codeUz', 'airportCodes', 'airportNames']"
            variant="none" :ui required :ui-menu="inputMenu">
            <template #option="{ option }">
                <span aria-hidden="true" />
                <div>
                    <div class="truncate">
                        {{ option['name' + currentLang] }}, {{ option['codeEn']
                        }}
                    </div>
                    <div class="truncate">
                        {{ option['countryName' + currentLang] }}
                    </div>
                </div>
            </template>
        </UInputMenu>
        <div class="absolute top-1/2 -translate-y-1/2 right-0 translate-x-1/2">
            <slot></slot>
        </div>
    </label>
</template>

<script setup>
const model = defineModel()
const props = defineProps({
    icon: {
        type: String,
        default: ''
    },
    label: {
        type: String,
        default: '',
    },
    type: {
        type: String,
        default: 'text'
    },
    disabled: {
        type: Boolean,
        default: false
    },
    options: {
        type: Array,
        required: true
    }
})
function format(d) {
    return d.toLocaleDateString('ru')
}
const isOpen = ref(false)

const { locale } = useI18n()
const currentLang = computed(() => {
    const langs = {
        en: 'En',
        ru: 'Ru',
        uz: 'Uz',
    }
    return langs[locale.value]
})
const options2 = computed(() => props.options.map(el => ({
    ...el,
    mylabel: `${el['name' + currentLang.value]} (${el.codeEn})`,
    airportCodes: el.airports ? [el.airports.map(a => [a.codeEn, a.codeRu, a.codeUz]).flat()] : [],
    airportNames: el.airports ? [el.airports.map(a => [a.nameEn, a.nameRu, a.nameUz]).flat()] : [],
})))

const ui = ref({
    wrapper: 'relative pl-4',
    base: 'relative block w-full disabled:cursor-not-allowed disabled:opacity-75 focus:outline-none border-0',
    form: 'form-input',
    rounded: 'rounded-md',
    placeholder: 'placeholder-gray-400 dark:placeholder-gray-500',
    size: {
        '2xs': 'text-xs',
        xs: 'text-xs',
        sm: 'text-sm',
        md: 'text-sm',
        lg: 'text-sm',
        xl: 'text-base'
    },
    gap: {
        '2xs': 'gap-x-1',
        xs: 'gap-x-1.5',
        sm: 'gap-x-1.5',
        md: 'gap-x-2',
        lg: 'gap-x-2.5',
        xl: 'gap-x-2.5'
    },
    padding: {
        '2xs': 'px-2 py-1',
        xs: 'px-2.5 py-1.5',
        sm: 'px-0 py-1.5',
        md: 'px-3 py-2',
        lg: 'px-3.5 py-2.5',
        xl: 'px-3.5 py-2.5'
    },
    leading: {
        padding: {
            '2xs': 'ps-7',
            xs: 'ps-8',
            sm: 'ps-0',
            md: 'ps-10',
            lg: 'ps-11',
            xl: 'ps-12'
        }
    },
    trailing: {
        padding: {
            '2xs': 'pe-7',
            xs: 'pe-8',
            sm: 'pe-0',
            md: 'pe-10',
            lg: 'pe-11',
            xl: 'pe-12'
        }
    },
})

const inputMenu = ref({
    container: 'z-20 group',
    trigger: 'flex items-center w-full',
    width: 'w-full',
    height: 'max-h-60',
    base: 'relative focus:outline-none overflow-y-auto scroll-py-1',
    background: 'bg-white dark:bg-gray-800',
    shadow: 'shadow-lg',
    rounded: 'rounded-md',
    padding: 'p-2',
    ring: 'ring-2 ring-my-green dark:ring-gray-700',
    empty: 'text-sm text-gray-400 dark:text-gray-500 px-2 py-1.5',
    option: {
        base: 'cursor-default select-none relative flex items-center justify-between gap-1',
        rounded: 'rounded-md',
        padding: 'px-0 py-1.5',
        size: 'text-sm',
        color: 'text-gray-900 dark:text-white',
        container: 'flex items-center gap-1.5 min-w-0',
        active: 'bg-gray-100 dark:bg-gray-900',
        inactive: '',
        selected: 'pe-7',
        disabled: 'cursor-not-allowed opacity-50',
        empty: 'text-sm text-gray-400 dark:text-gray-500 px-2 py-1.5',
        icon: {
            base: 'flex-shrink-0 h-5 w-5',
            active: 'text-gray-900 dark:text-white',
            inactive: 'text-gray-400 dark:text-gray-500'
        },
        selectedIcon: {
            wrapper: 'absolute inset-y-0 end-0 flex items-center',
            padding: 'pe-2',
            base: 'h-5 w-5 text-gray-900 dark:text-white flex-shrink-0'
        },
        avatar: {
            base: 'flex-shrink-0',
            size: '2xs'
        },
        chip: {
            base: 'flex-shrink-0 w-2 h-2 mx-1 rounded-full'
        }
    },
    transition: {
        leaveActiveClass: 'transition ease-in duration-100',
        leaveFromClass: 'opacity-100',
        leaveToClass: 'opacity-0'
    },
    popper: {
        placement: 'bottom-end'
    },
    default: {
        selectedIcon: 'i-heroicons-check-20-solid',
        trailingIcon: 'i-heroicons-chevron-down-20-solid',
        empty: {
            label: 'No options.'
        },
        optionEmpty: {
            label: 'No results for "{query}".'
        }
    },

}
)

</script>